<template>
    <div class="product-integration">
        <h4>สินค้าที่ร่วมรายการโปรโมชั่น</h4>

        <div class="all-product">
            <switch-button
                v-model="isAllProduct">
                ร่วมโปรโมชั่นสินค้าทุกรายการในร้านค้า
            </switch-button>
        </div>

        <div class="panel-product">
            <div class="blocker" v-if="isAllProduct"></div>

            <div class="tab-options">
                <button :class="{ 'active': integrateOption === 1 }" @click="integrateOption = 1">ระบุสินค้าราย SKU</button>
                <button :class="{ 'active': integrateOption === 2 }" @click="integrateOption = 2">ระบุสินค้าด้วยยี่ห้อ</button>
            </div>

            <div class="integrate-by-sku" v-if="integrateOption === 1">
                <div class="search">
                    <button class="btn-toggle" @click="toggleForm()" v-if="!isToggleForm">กรองสินค้าในร้าน</button>

                    <div class="form" v-if="form && isToggleForm">
                        <button class="btn-hide-form" @click="toggleForm()">
                            <b-icon icon="dash-square"></b-icon>
                        </button>

                        <div class="row">
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>สถานะสินค้า</label>
                                    <dropdown
                                        v-model="form.status"
                                        placeholder="สถานะ"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.status">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>ยี่ห้อยาง</label>
                                    <dropdown
                                        v-model="form.brand"
                                        placeholder="ยี่ห้อยาง"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.brand"
                                        @change="changeBrand($event)">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>รุ่นยาง</label>
                                    <dropdown
                                        v-model="form.model"
                                        placeholder="รุ่น"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.model">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>SKU</label>
                                    <input type="text"
                                        placeholder="SKU"
                                        v-model="form.sku"
                                        class="square"
                                    />
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>ความกว้างยาง</label>
                                    <dropdown
                                        v-model="form.width"
                                        placeholder="ความกว้างยาง"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.width"
                                        @change="changeTireSizeOption()">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>ซีรี่ย์</label>
                                    <dropdown
                                        v-model="form.ratio"
                                        placeholder="ซีรี่ย์"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.ratio"
                                        @change="changeTireSizeOption()">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>ขอบล้อ</label>
                                    <dropdown
                                        v-model="form.diameter"
                                        placeholder="ขอบล้อ"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.diameter"
                                        @change="changeTireSizeOption()">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>ปีผลิต</label>
                                    <dropdown
                                        v-model="form.year"
                                        placeholder="ปีผลิต"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.year">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-6 col-md-3">
                                <div class="input-field">
                                    <label>กรองสินค้าร่วมรายการ</label>
                                    <dropdown
                                        v-model="form.integrateType"
                                        placeholder="กรองสินค้าร่วมรายการ"
                                        :classes="{ input: [ 'square', 'input-option' ] }"
                                        :options="optionForFilter.integrate">
                                    </dropdown>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2 text-center">
                            <button class="btn btn-main btn-search" @click="submitSearch()"><font-awesome-icon :icon="['fas', 'search']" class="icon" /> ค้นหา</button>
                            <button class="btn btn-reset" @click="resetForm()">รีเซ็ต</button>
                        </div>
                    </div>
                </div>

                <label class="result-text">ผลการค้นหา <strong>{{ totalResult }}</strong> รายการ</label>

                <div class="action">
                    <div>
                        <button class="btn btn-sm btn-add-selected square" @click="toggleSelctedProduct(true)">ร่วมรายการสินค้าที่เลือก ({{ resultList.filter(x => x.isSelect).length }})</button>
                        <button class="btn btn-sm btn-remove-selected square" @click="toggleSelctedProduct(false)">ถอนสินค้าที่เลือก ({{ resultList.filter(x => x.isSelect).length }})</button>
                    </div>
                </div>

                <div class="vue-dragscoll mt-3" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                    <table>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <button class="btn btn-sm btn-outline-secondary"
                                        v-if="!isSelectAll"
                                            @click="toggleAllSelection(true)">
                                    </button>
                                    <button class="btn btn-sm btn-primary"
                                        v-if="isSelectAll"
                                            @click="toggleAllSelection(false)">
                                        <b-icon icon="check"></b-icon>
                                    </button>
                                </th>
                                <th class="sortable"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.INTEGRATE)">
                                    ร่วมรายการ
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE">
                                    </b-icon>
                                </th>
                                <th class="sortable"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.ID)">
                                    รหัส
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.ID">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.ID">
                                    </b-icon>
                                </th>
                                <th class="sortable"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.BRAND)">
                                    ยี่ห้อ/รุ่น
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND">
                                    </b-icon>
                                </th>
                                <th class="sortable"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.SIZE)">
                                    ขนาด
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE">
                                    </b-icon>
                                </th>
                                <th class="sortable text-center"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.YEAR)">
                                    ปีผลิต
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR">
                                    </b-icon>
                                </th>
                                <th class="sortable text-end"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.QUANTITY)">
                                    จำนวน
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY">
                                    </b-icon>
                                </th>
                                <th class="sortable text-end"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.PRICE)">
                                    ราคา (ติดตั้ง)
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE">
                                    </b-icon>
                                </th>
                                <th class="sortable text-end"
                                    :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY }"
                                    @click="sortStock(constants.SORT_STOCK_COLUMN.PRICE_DELIVERY)">
                                    ราคา (จัดส่ง)
                                    <b-icon icon="chevron-down" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY">
                                    </b-icon>
                                    <b-icon icon="chevron-up" 
                                        class="icon"
                                        v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                            && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY">
                                    </b-icon>
                                </th>
                                <th class="text-center">สถานะ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in resultList" :key="index">
                                <td class="text-center">
                                    <button class="btn btn-sm btn-outline-secondary"
                                        v-if="!item.isSelect"
                                        @click="toggleSelection(item, true)">
                                    </button>
                                    <button class="btn btn-sm btn-primary"
                                        v-if="item.isSelect"
                                        @click="toggleSelection(item, false)">
                                        <b-icon icon="check"></b-icon>
                                    </button>
                                </td>
                                <td class="text-center">
                                    <b-icon icon="check" v-if="item.isIntegrated" class="icon-integrated"></b-icon>
                                </td>
                                <td>
                                    {{ item.sku }}		
                                </td>
                                <td>{{ item.brand }} {{ item.model }}</td>
                                <td>{{ item.size }}</td>
                                <td class="text-center">{{ item.year }}</td>
                                <td class="text-end">{{ item.stock }}</td>
                                <td class="text-end">{{ item.price }}</td>
                                <td class="text-end">{{ item.priceDelivery }}</td>
                                <td class="text-center">
                                    <strong>{{ item.statusText }}</strong>
                                    <br />
                                    <div class="status-display"><span class="display" :class="{ 'active': item.isShowOnWeb }"></span> {{ item.isShowOnWeb ? 'แสดงบนเว็บ' : 'ไม่แสดงบนเว็บ' }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="no-data" v-if="isResultReady && resultList.length === 0">
                    <b-icon icon="box-seam"></b-icon>
                    <span>ยังไม่มีสินค้าในร้าน</span>
                </div>

                <paging
                    @page="changePage($event)"
                    :activePage="activePage"
                    :totalPage="totalPage"
                    class="mt-4">
                </paging>
            </div>

            <div class="integrate-by-brand" v-if="integrateOption === 2">
                <div class="form">
                    <div class="row">
                        <div class="col-5 col-md-4">
                            <div class="input-field mb-1">
                                <label class="mb-0">ยี่ห้อยาง</label>
                            </div>
                        </div>
                        <div class="col-5 col-md-4">
                            <div class="input-field mb-1">
                                <label class="mb-0">รุ่นยาง</label>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-for="(item, index) in brandIntegrations" :key="index">
                        <div class="col-5 col-md-4">
                            <div class="input-field">
                                <dropdown
                                    v-model="item.brand"
                                    placeholder="-- เลือกยี่ห้อยาง --"
                                    :classes="{ input: [ 'square', 'input-option' ] }"
                                    :options="optionForBrands.brand[index]"
                                    @change="changeOptionBrandIntegration($event, index)">
                                </dropdown>
                            </div>
                        </div>
                        <div class="col-5 col-md-4">
                            <div class="input-field">
                                <dropdown
                                    v-model="item.model"
                                    placeholder="รุ่นทั้งหมด"
                                    :classes="{ input: [ 'square', 'input-option' ] }"
                                    :options="optionForBrands.model[index]">
                                </dropdown>
                            </div>
                        </div>
                        <div class="col-2 col-md-4">
                            <button class="btn-remove" @click="removeIntegrationBrand(index)">X</button>
                        </div>
                    </div>

                    <div class="row mt-1 mb-3">
                        <div class="col-10 col-md-8">
                            <button class="btn-append" @click="appendIntegrationBrand()">เพิ่มตัวกรองยี่ห้อยาง</button>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-10 col-md-8 text-center">
                            <button class="btn btn-warning btn-save">บันทึกยี่ห้อสินค้าร่วมรายการ</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-save-product-loader
            :isDisplay="isLoadSaveProduct"
            :savedProduct="savedProduct"
            :totalProduct="totalProduct">
        </modal-save-product-loader>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import MixinDragScroll from '@/mixins/dragScroll';
import SellerService from '@/services/sellerService';
import MasterService from '@/services/masterService';
import ModalSaveProductLoader from '@/components/seller/stocks/ModalSaveProductLoader';

export default {
	mixins: [ MixinDragScroll ],
    components: { ModalSaveProductLoader },
    data() {
		return {
            isAllProduct: false,
            isSelectAll: false,
            isResultReady: false,
            isToggleForm: false,
            integrateOption: 1,
			resultList: [],
			activePage: 1,
			totalPage: 0,
            totalResult: 0,
            brandIntegrations: [],
            optionForBrands: {
                brand: [],
                model: []
            },
            form: null,
			searchFilter: null,
            optionForFilter: {
                status: [],
                brand: [],
                model: [],
                width: [],
                ratio: [],
                diameter: [],
                year: Helper.initStockYearOption(),
                integrate: []
            },
            constants: Constants,
			currentSortDirection: Constants.SORT_STOCK_DIRECTION.DEFAULT,
			currentSortColumn: Constants.SORT_STOCK_COLUMN.DEFAULT,
            isLoadSaveProduct: false,
            savedProduct: 0,
            totalProduct: 0
		};
	},
	props: {
        keyForIntegration: Number,
        typeForIntegration: String
	},
    mounted() {
		this.initOptionStatus();
		this.initTireBrand();
        this.initTireOption();
        this.initIntegrateOption();

        this.form = this.initForm();
        this.searchFilter = this.setupSearchFilter();

        this.getStock();
	},
	methods: {
        appendIntegrationBrand() {
            this.optionForBrands.brand.push(this.optionForFilter.brand);
            this.optionForBrands.model.push([]);
            
            this.brandIntegrations.push({
                brand: '',
                model: ''
            });
        },
        removeIntegrationBrand(index) {
            this.brandIntegrations.splice(index, 1);
            this.optionForBrands.brand.splice(index, 1);
            this.optionForBrands.model.splice(index, 1);
        },
        async changeOptionBrandIntegration(brand, index) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getProductModel(brand);
                loader.hide();

                // Vue array will not update props if not using splice
                this.optionForBrands.model.splice(index, 1, Helper.setupOption(result.data));
            } else {
                this.optionForBrands.model[index] = [];
            }

            this.brandIntegrations[index].model = '';
        },
        toggleForm() {
            this.isToggleForm = !this.isToggleForm;
        },
        initForm() {
			return {
				status: Constants.SELLER_STOCK_STATUS.ALL,
				brand: '',
				model: '',
                width: '',
                ratio: '',
                diameter: '',
                year: '',
                integrateType: 'all',
                integrateKey: this.keyForIntegration,
                integrateGroup: this.typeForIntegration,
                sku: ''
			};
		},
        initOptionStatus() {
            const option = [];

            Helper.getStatusList().forEach((item) => {
                option.push({
                    id: item.statusCode,
                    name: item.statusText
                });
            });

            this.optionForFilter.status = option;
        },
        initIntegrateOption() {
            const option = [];

            option.push({
                id: 'all',
                name: 'ทั้งหมด'
            });

            option.push({
                id: 'select',
                name: 'สินค้าที่ร่วมรายการ'
            });

            option.push({
                id: 'unselect',
                name: 'สินค้าที่ไม่ได้ร่วมรายการ'
            });

            this.optionForFilter.integrate = option;
        },
		setupSearchFilter() {
			return {
                status: this.form.status,
				param: {
                    sku: this.form.sku,
					brand: this.form.brand,
					model: this.form.model,
					width: this.form.width,
					ratio: this.form.ratio,
					diameter: this.form.diameter,
                    promotion: '',
                    year: this.form.year,
                    integrateType: this.form.integrateType,
                    integrateKey: this.form.integrateKey,
                    integrateGroup: this.form.integrateGroup
				},
				page: this.activePage,
				sortDirection: this.currentSortDirection,
				sortColumn: this.currentSortColumn
			}
		},
        async initTireBrand() {
            const result = await MasterService.getProductBrand();

			this.optionForFilter.brand = Helper.setupOption(result.data);

            // Inital
            this.appendIntegrationBrand();
        },
        async initTireOption() {
            const result = await MasterService.getAllTireSizeOption();
            
            this.optionForFilter.width = Helper.setupOption(result.data.width);
            this.optionForFilter.ratio = Helper.setupOption(result.data.ratio);
            this.optionForFilter.diameter = Helper.setupOption(result.data.diameter);
        },
        async changeTireSizeOption() {
            setTimeout(async () => {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getTireSizeByCondition(this.form);
                loader.hide();

                this.optionForFilter.width = Helper.setupOption(result.data.width);
                this.optionForFilter.ratio = Helper.setupOption(result.data.ratio);
                this.optionForFilter.diameter = Helper.setupOption(result.data.diameter);
            });
        },
        async changeBrand(brand) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getProductModel(brand);
                loader.hide();

				this.optionForFilter.model = Helper.setupOption(result.data);
            } else {
                this.optionForFilter.model = [];
            }

            this.form.model = '';
        },
		submitSearch() {
			this.changePage(1);
		},
        resetForm() {
			this.form = this.initForm();
			this.submitSearch();
		},
		async getStock() {
            this.isResultReady = false;
            this.searchFilter = this.setupSearchFilter();

			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStock(this.searchFilter);

			// Clear data to avoid trigger change in table
			this.resultList = [];

			// After result ready
            this.isSelectAll = false;
			this.totalPage = result.data.totalPage;
            this.totalResult = result.data.totalResult;
			let resultList = result.data.resultList;
            // For Selection
            // resultList = resultList.map(x => ({ ...x, isIntegrated: this.products.indexOf(x.id) > -1, isSelect: false }));
            
            this.resultList = resultList;
            this.isResultReady = true;

            loader.hide();
		},
		changePage(page) {
			this.activePage = page;
			this.getStock();
		},
        toggleSelection(item, value) {
            item.isSelect = value;
		},
		toggleAllSelection(value) {
            this.isSelectAll = value;

            this.resultList.forEach((item) => {
                this.toggleSelection(item, value);
            });
		},
        async toggleSelctedProduct(flag) {
            const selectedList = this.resultList.filter(x => x.isSelect);

            if (selectedList.length > 0) {
                this.savedProduct = 0;
                this.totalProduct = selectedList.length;
                this.isLoadSaveProduct = true;

                for (let item of selectedList) {
                    this.savedProduct++;

                    if (flag !== item.isIntegrated) {
                        switch (this.typeForIntegration) {
                            case 'promotion':
                                await SellerService.toggleProductPromotion(item.id, flag, this.keyForIntegration);
                                break;

                            case 'service':
                                await SellerService.toggleProductService(item.id, flag, this.keyForIntegration);
                                break;

                            case 'warranty':
                                await SellerService.toggleProductWarranty(item.id, flag, this.keyForIntegration);
                                break;
                        }
                    }
                }

                this.isLoadSaveProduct = false;

                // Done toggle selection
                this.$emit('reloadContent', () => this.getStock());
            }
        },
		sortStock(column) {
            if (this.currentSortColumn === column) {
				switch (this.currentSortDirection) {
					case Constants.SORT_STOCK_DIRECTION.ASC:
					this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.DESC;
					break;

					case Constants.SORT_STOCK_DIRECTION.DESC:
					this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.ASC;
					break;
				}
			} else {
				this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.ASC;
			}

			this.currentSortColumn = column;

			// Reset Page and reload
			this.changePage(1);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-integration {
    h4 {
        margin: 30px 0 20px;
        padding: 10px 0 10px 15px;
        font-size: 15px;
        color: #333;
        background: #f2f2f2;
        border-left: 4px solid #2ab19e;
    }

    .all-product {
        margin-bottom: 20px;
        color: #cc0000;
    }

    .panel-product {
        position: relative;

        .blocker {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            z-index: 1;
            background: #fff;
            cursor: not-allowed;
        }

        .tab-options {
            display: flex;
            border-bottom: 1px solid #aaa;
            margin-bottom: 20px;

            button {
                background: #fff;
                border: 1px solid #aaa;
                opacity: 0.5;
                outline: none;
                font-size: 13px;
                padding: 5px 10px;
                margin-right: 5px;
                margin-bottom: -1px;

                &.active {
                    opacity: 1;
                    border-bottom: 1px solid #fff;
                }
            }
        }

        .integrate-by-sku {
            .btn-toggle {
                border: 1px dashed #ccc;
                padding: 7px 15px;
                background: #fff;
                color: #555;
                font-size: 13px;
                width: 100%;
                display: block;
            }

            .form {
                position: relative;
                padding: 20px;
                border: 1px dotted #ccc;
                @include borderRadius(10px);

                .btn-hide-form {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    outline: none;
                    border: none;
                    background: none;
                    font-size: 18px;
                    line-height: 18px;
                    padding: 0;
                    color: #aaa;
                }

                label {
                    margin-bottom: 5px;
                    color: #333;
                }

                .input-field {
                    input {
                        font-size: 13px;
                        padding: 10px 15px;
                        border: 1px solid #ccc;
                    }
                }

                .btn {
                    padding: 3px 5px;
                    width: 120px;
                    font-size: 13px;

                    .icon {
                        font-size: 12px;
                        margin-right: 5px;
                    }

                    &.btn-reset {
                        background: #ccc;
                    }
                }
            }

            .result-text {
                margin: 10px 0;
                font-size: 13px;
                display: block;

                strong {
                    color: #cc0000;
                }
            }

            .action {
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: $screenExtraSmall) {
                    display: block;
                }

                .btn {
                    padding: 3px 8px;
                    font-size: 13px;
                    background: none;

                    @media only screen and (max-width: $screenSmall) {
                        margin-left: 0;
                        margin-top: 5px;
                        display: block;
                        width: 100%;
                    }

                    & + .btn {
                        margin-left: 2px;

                        @media only screen and (max-width: $screenSmall) {
                            margin-left: 0;
                        }
                    }

                    &.btn-add-all {
                        border: 1px solid #2ab19e;
                        color: #2ab19e;
                    }

                    &.btn-remove-all {
                        border: 1px solid #bf3f4c;
                        color: #bf3f4c;
                    }

                    &.btn-add-selected {
                        border: 1px solid #4aa1bb;
                        color: #4aa1bb;
                    }

                    &.btn-remove-selected {
                        border: 1px solid #e56e35;
                        color: #e56e35;
                    }
                }
            }

            table {
                width: 100%;
                font-size: 12px;
                border: 1px solid #ddd;

                th, td {
                    padding: 10px;
                }

                thead {
                    th {
                        background: #eee;
                        color: #000;
                        font-weight: normal;
                        border-left: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        min-width: 63px;
                        font-size: 12px;
                        line-height: 15px;

                        &.action-colummn {
                            min-width: 40px;
                        }

                        &.sortable {
                            cursor: pointer;
                            min-width: 70px;

                            &:hover {
                                text-decoration: underline;
                                opacity: 0.7;
                            }

                            .icon {
                                margin-left: 5px;
                                color: #666;
                            }
                        }
                    }
                }

                tbody {
                    td {
                        color: #555;
                        border-bottom: 1px solid #ddd;
                        border-left: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        vertical-align: middle;

                        .status-display {
                            @extend .sellerProductStatusDisplay;
                        }
                    }
                }

                .icon-integrated {
                    color: #2ab19e;
                    font-size: 24px;
                }

                .btn {
                    padding: 0;
                    width: 22px;
                    height: 22px;
                    font-size: 14px;
                    line-height: 15px;
                    @include borderRadius(0);
                }
            }
        }

        .integrate-by-brand {
            min-height: 500px;

            .btn-append {
                background: #fff;
                border: 1px dashed #ccc;
                color: #999;
                font-size: 13px;
                width: 100%;
                height: 36px;
            }

            .btn-remove {
                background: #fff;
                border: none;
                color: #cc0000;
                font-size: 14px;
            }

            .btn-save {
                padding: 8px 20px;
                font-size: 14px;
            }
        }
    }
}

::v-deep .form {
	.form-option {
        .dropdown {
            .dropdown-wrapper {
                span {
                    font-size: 14px;
                }
            }
        }

		.input-option {
			font-size: 13px;

			&[readonly] {
				background: #fff;
			}
		}
	}
}
</style>