<template>
	<section>
		<h2>สร้างโปรโมชั่น</h2>

		<router-link :to="routerPath.SELLER_PRODUCTS_PROMOTION_V2" class="btn btn-warning btn-back">กลับไปหน้ารายการ</router-link>

		<div class="box">
			<div class="form" v-if="form">
				<div class="row">
					<div class="col col-12">
						<div class="input-field">
							<label>ชื่อโปรโมชั่น</label>
							<input type="text"
								v-model="form.title"
								maxlength="100"
								placeholder="ตั้งชื่อโปรโมชั่น"
								class="square"
								:class="{ 'invalid': error.title }" />
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col col-12 col-sm-6">
						<div class="input-field">
							<label>ตั้งแต่วันที่</label>
							<div class="input-date-icon">
								<datepicker
									v-model="form.fromDate"
									@input="changeDateStart()"
									:disabled-dates="getDisableDate()"
									:input-class="[ 'input-date', 'square', (error.fromDate ? 'invalid' : '') ]"
									:language="calendarTH"
									:full-month-name="true"
									calendar-class="datepicker-box"
									placeholder="วันที่เริ่มต้น"
									format="dd/MM/yyyy">
								</datepicker>
								<b-icon icon="calendar"></b-icon>
							</div>
						</div>
					</div>
					<div class="col col-12 col-sm-6">
						<div class="input-field">
							<label>จนถึง</label>
							<div class="input-date-icon">
								<datepicker
									v-model="form.toDate"
									:disabled-dates="getDisableDate()"
									:input-class="[ 'input-date', 'square', (error.toDate ? 'invalid' : '') ]"
									:language="calendarTH"
									:full-month-name="true"
									calendar-class="datepicker-box"
									placeholder="วันที่สิ้นสุด"
									format="dd/MM/yyyy">
								</datepicker>
								<b-icon icon="calendar"></b-icon>
							</div>
						</div>
					</div>
					<div class="col col-12">
						<div class="input-field">
							<label>เงื่อนไขและคำบรรยาย</label>
							<input type="text"
								v-model="form.description"
								maxlength="500"
								placeholder="บรรยายเงื่อนไข"
								class="square"
								:class="{ 'invalid': error.description }" />
						</div>
					</div>
				</div>

				<div class="sub-promotions">
					<div class="input-field">
						<label>ส่วนลด/สิทธิประโยชน์</label>
						<span class="note">* กรณีส่วนลดจะเป็นคำนวณไล่จากเงื่อนไขจากบนลงล่าง</span>
						<div class="item"
							v-for="(item, index) in subPromotion"
							:key="index">
							<div class="priority">
								<span>{{ (index + 1) }}</span>
							</div>

							<div class="wrapper">
								<seller-sub-promotion-form
									class="sub-form"
									:error="errorSubPromotion[index]"
									:optionPromotion="optionPromotion">
								</seller-sub-promotion-form>

								<button class="btn-remove">
									<b-icon icon="x"></b-icon>
								</button>
							</div>
							
						</div>
					</div>

					<button class="btn-append" @click="appendSubPromotion()">เพิ่มรายการส่วนลด/ของแถม/สิทธิประโยชน์</button>
				</div>

				<div class="text-center mt-4">
					<button class="btn btn-danger btn-submit" @click="submitPromotion()"><b-icon icon="pencil" class="icon"></b-icon> บันทึก</button>
				</div>
			</div>
		</div>

		<seller-product-integration
			class="mt-4"
			:keyForIntegration="promotionId"
			typeForIntegration="promotion">
		</seller-product-integration>

		<modal-save-product-loader
			:isDisplay="isLoadSaveProduct"
			:savedProduct="savedProduct"
			:totalProduct="totalProduct">
		</modal-save-product-loader>
	</section>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import ModalSaveProductLoader from '@/components/seller/stocks/ModalSaveProductLoader';
import SellerProductIntegration from '@/components/seller/stocks/ProductIntegration';
import SellerSubPromotionForm from '@/components/seller/stocks/SubPromotionForm';
import MasterService from '@/services/masterService';
// import SellerService from '@/services/sellerService';

export default {
    components: {
		ModalSaveProductLoader,
		SellerProductIntegration,
		SellerSubPromotionForm
	},
    data() {
		return {
			routerPath: RouterPath,
            calendarTH: th,
			optionPromotion: [],
			promotionId: 1, // Mockup
            form: null,
			subPromotion: [],
            error: null,
			errorSubPromotion: [],
			promotionForIntegration: {
				id: 0,
				title: '',
				description: '',
				products: null,
				data: null
			},
            isLoadSaveProduct: false,
            savedProduct: 0,
            totalProduct: 0
		};
	},
	async mounted() {
		await this.initPromotionList();
		this.initForm();
		this.initError();
	},
	methods: {
		async initPromotionList() {
			const result = await MasterService.masterGetPromotionList(this.selectedCategory);
			this.optionPromotion = [];

            result.data.forEach((item) => {
                this.optionPromotion.push({
                    id: item.id,
                    name: item.name
                });
            });
		},
        initForm() {
            this.form = {
                id: '',
                title: '',
                fromDate: '',
                toDate: '',
                description: ''
            };
        },
        initError() {
            this.error = {
                title: false,
                fromDate: false,
                toDate: false,
                description: false
            };
        },
		initErrorSubPromotion() {
			return {
                promotionType: false,
                fromDate: false,
                toDate: false,
                description: false,
                qtyCondition: false,
                deliveryMinQty: false,
                installmentMaxPeriod: false,
                charge: {
                    month3: false,
                    month4: false,
                    month6: false,
                    month10: false,
                }
            };
		},
        changeDateStart() {
            this.form.toDate = '';
        },
        getDisableDate() {
            return { to: moment(moment(), 'YYYY-MM-DD').subtract(1, 'day').toDate() };
        },
		appendSubPromotion() {
			this.subPromotion.push({});
			this.errorSubPromotion.push(this.initErrorSubPromotion());
		},
        async submitPromotion() {
            if (this.validate()) {
                // Prevent date get converted
                this.form.fromDate = moment(this.form.fromDate).format('YYYY-MM-DD').toString();
                this.form.toDate = moment(this.form.toDate).format('YYYY-MM-DD').toString();
                
                this.isLoadSaveProduct = true;
                // const result = await SellerService.savePromotion(this.form);

                // if (result.data?.success) {
                //     if (this.editData) {
                //         this.savedProduct = 0;

                //         for (let sellerProductId of this.editData.productIntegrated) {
                //             this.savedProduct++;
                //             await SellerService.updatePromotionProduct(sellerProductId, this.category);
                //         }
                //     }

				// 	this.hide(true);
				// } else {
				// 	this.$swal(Helper.warningAlert('ไม่สามารถบันทึกโปรโมชั่นได้', result.data?.message));
				// }

                this.isLoadSaveProduct = false;
            }
        },
        validate() {
            this.initError();

            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.title) {
                this.error.title = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.fromDate) {
                this.error.fromDate = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.toDate) {
                this.error.toDate = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.description) {
                this.error.description = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามารถบันทึกโปรโมชั่นได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/PromotionForm.scss';
</style>