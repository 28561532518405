<template>
    <div class="form" v-if="form">
        <div class="row">
            <div class="col col-12">
                <div class="input-field">
                    <dropdown
                        v-model="form.promotionType"
                        placeholder="เลือกประเภทโปรโมชั่น"
                        :classes="{
                            input: [
                                'square',
                                'input-option',
                                error.promotionType ? 'invalid' : ''
                            ]
                        }"
                        :options="optionPromotion">
                    </dropdown>
                </div>
            </div>
        </div>
        <template v-if="form.promotionType">
            <div class="row">
                <div class="col col-12 col-sm-6"
                    v-if="form.promotionType === 'discountAmount'
                    || form.promotionType === 'discountPercent'
                    || form.promotionType === 'discountTurnPart'">
                    <div class="input-field">
                        <label>กำหนดส่วนลด ต่อเส้น</label>
                        <div class="input-unit">
                            <input type="number"
                                @keypress="$AppUtility.inputOnlyNumber($event)"
                                v-model="form.amount"
                                :placeholder="displayDiscountPlaceholder()"
                                class="square"
                                :class="{ 'invalid': error.amount }" />
                            <span>{{ displayDiscountUnit() }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-6"
                    v-if="form.promotionType === 'discountOrderAmt'
                        || form.promotionType === 'discountOrderPercent'">
                    <div class="input-field">
                        <label>กำหนดส่วนลดต่อรายการสั่งซื้อ</label>
                        <span class="sub">* ใช้ได้กับรายการสั่งซื้อที่มีจำนวน 4 เส้นเท่านั้น</span>
                        <div class="input-unit">
                            <input type="number"
                                @keypress="$AppUtility.inputOnlyNumber($event)"
                                v-model="form.amount"
                                :placeholder="displayDiscountPlaceholder()"
                                class="square"
                                :class="{ 'invalid': error.amount }" />
                            <span>{{ displayDiscountUnit() }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="form.promotionType === 'delivery'">
                <div class="col col-12 col-sm-6">
                    <div class="input-field">
                        <div class="delivery-condition">
                            <div class="checkbox">
                                <checkbox
                                    v-model="form.delivery.requireMinQty"
                                    @change="changeDeliveryRequireQty($event)">
                                    กำหนดจำนวนเส้นขั้นต่ำ
                                </checkbox>
                            </div>
                            <div class="input">
                                <div class="blocker" v-if="!form.delivery.requireMinQty"></div>
                                <select
                                    v-model="form.delivery.minQty"
                                    class="square"
                                    :class="{ 'invalid': error.deliveryMinQty }">
                                    <option disabled :value="null">-- จำนวนขั้นต่ำ --</option>
                                    <option value="1">1 เส้น</option>
                                    <option value="2">2 เส้น</option>
                                    <option value="3">3 เส้น</option>
                                    <option value="4">4 เส้น</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-12">
                    <div class="input-field">
                        <label>พื้นที่ที่ร่วมรายการ</label>
                        <div class="delivery-zone">
                            <div class="checkbox">
                                <checkbox
                                    v-model="form.delivery.zone1">
                                    โซนที่ 1
                                </checkbox>
                            </div>
                            <div class="checkbox">
                                <checkbox
                                    v-model="form.delivery.zone2">
                                    โซนที่ 2
                                </checkbox>
                            </div>
                            <div class="checkbox">
                                <checkbox
                                    v-model="form.delivery.zone3">
                                    โซนที่ 3
                                </checkbox>
                            </div>
                            <div class="checkbox">
                                <checkbox
                                    v-model="form.delivery.zone4">
                                    โซนที่ 4
                                </checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12" v-if="form.promotionType === 'buy1get1'">
                    <div class="input-field">
                        <div class="qty-condition">
                            <b-form-group>
                                <b-form-radio 
                                    v-model="form.qtyCondition"
                                    name="qtyCondition" 
                                    value="option1">
                                    ต้องซื้ออย่างน้อย 2 ชิ้น
                                </b-form-radio>
                                <b-form-radio 
                                    v-model="form.qtyCondition" 
                                    name="qtyCondition" 
                                    value="option2">
                                    ต้องซื้ออย่างน้อย 4 ชิ้น
                                </b-form-radio>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col col-12" v-if="form.promotionType === 'installment'">
                    <div class="input-field">
                        <label>จำนวนเดือนที่ผ่อนได้</label>
                        <select
                            v-model="form.installmentMaxPeriod"
                            class="square"
                            :class="{ 'invalid': error.installmentMaxPeriod }"
                            @change="changeInstallmentMaxPeriod($event)">
                            <option disabled :value="null">-- เลือกจำนวนเดือน --</option>
                            <option v-for="(item, index) in optionInstallmentPeriod" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <!-- Hide Feature -->
                <!-- <div class="col col-12" v-if="form.promotionType === 'installment' && form.installmentMaxPeriod">
                    <div class="input-field">
                        <label>
                            สำหรับเพิ่มราคาสินค้าผ่อน 0%
                            <span class="sub">* ไม่จำเป็นต้องติ๊กด้านล่างถ้าหากต้องการขายตามราคาปกติที่ตั้งไว้</span>
                        </label>
                        <div class="installment-charge" v-for="(item, index) in installmentChargeList" :key="index">
                            <div class="checkbox">
                                <checkbox
                                    v-model="form.installmentCharge[item.property].isEnable"
                                    @change="changeChargeEnable($event, item.property)"
                                    class="checker-charge">
                                    กรณีผ่อน {{ item.month }} เดือน
                                </checkbox>
                            </div>
                            <div class="option">
                                <div class="blocker" v-if="!form.installmentCharge[item.property].isEnable"></div>
                                <select class="square" 
                                        v-model="form.installmentCharge[item.property].chargeType">
                                    <option v-for="(option, optionIndex) in optionChargeType" :key="optionIndex" :value="option.key">{{ option.text }}</option>
                                </select>
                            </div>
                            <div class="input">
                                <div class="blocker" v-if="!form.installmentCharge[item.property].isEnable"></div>
                                <div class="input-unit">
                                    <input type="tel"
                                        v-model="form.installmentCharge[item.property].charge"
                                        @keypress="$AppUtility.inputOnlyNumber($event)"
                                        :placeholder="form.installmentCharge[item.property].chargeType === 'percent' ? '% ราคาที่เพิ่ม' : 'ระบุราคาที่เพิ่ม'"
                                        class="square"
                                        maxlength="6"
                                        :class="{ 'invalid': error.charge[item.property] }" />
                                    <span v-if="form.installmentCharge[item.property].chargeType === 'percent'">%</span>
                                    <span v-if="form.installmentCharge[item.property].chargeType === 'amount'">บาท</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </template>

        <modal-save-product-loader
			:isDisplay="isLoadSaveProduct"
			:savedProduct="savedProduct"
			:totalProduct="totalProduct">
		</modal-save-product-loader>
    </div>
</template>

<style lang="scss" scoped>
@import '@/components/seller/style/PromotionForm.scss';
</style>

<script>
import Helper from '@/services/helper';
import ModalSaveProductLoader from '@/components/seller/stocks/ModalSaveProductLoader';

export default {
    components: { ModalSaveProductLoader },
    data() {
		return {
            form: null,
            optionInstallmentPeriod: [
                { id: '3', name: '3 เดือน' },
                { id: '4', name: '3, 4 เดือน' },
                { id: '6', name: '3, 4, 6 เดือน' },
                { id: '10', name: '3, 4, 6, 10 เดือน' },
            ],
            optionChargeType: [
                { key: 'percent', text: 'ระบุด้วย %' },
                { key: 'amount', text: 'ระบุด้วยราคา' }
            ],
            installmentChargeList: [],
            isLoadSaveProduct: false,
            savedProduct: 0,
            totalProduct: 0
		};
	},
    props: {
        editData: Object,
        error: Object,
        optionPromotion: Array
    },
    mounted() {
        this.form = this.initForm();
        console.log(this.form.promotionType);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
            return {
                id: this.editData ? this.editData.id : '',
                promotionType: this.editData ? this.editData.alias : '',
                amount: this.editData ? this.editData.amount : '',
                description: this.editData ? this.editData.description : '',
                qtyCondition: this.editData ? this.editData.qtyCondition : '',
                delivery: {
                    requireMinQty: this.editData && this.editData.delivery ? this.editData.delivery.requireMinQty : false,
                    minQty: this.editData && this.editData.delivery ? this.editData.delivery.minQty : null,
                    zone1: this.editData && this.editData.delivery ? this.editData.delivery.zone1 : true,
                    zone2: this.editData && this.editData.delivery ? this.editData.delivery.zone2 : true,
                    zone3: this.editData && this.editData.delivery ? this.editData.delivery.zone3 : true,
                    zone4: this.editData && this.editData.delivery ? this.editData.delivery.zone4 : true,
                },
                installmentMaxPeriod: this.editData ? this.editData.installmentMaxPeriod : null,
                    installmentCharge: this.editData ? this.editData.installmentCharge : {
                        month3: { isEnable: false, chargeType: '', charge: '' },
                        month4: { isEnable: false, chargeType: '', charge: '' },
                        month6: { isEnable: false, chargeType: '', charge: '' },
                        month10: { isEnable: false, chargeType: '', charge: '' },
                    }
            };
        },
        displayDiscountPlaceholder() {
            let placeholder = '';

            switch (this.form.promotionType) {
                case 'discountAmount':
                case 'discountTurnPart':
                    placeholder = 'ราคาที่จะลด'
                    break;

                case 'discountPercent':
                    placeholder = 'เปอร์เซ็นส่วนลด'
                    break;

                case 'discountOrderAmt':
                    placeholder = 'ราคาที่จะลดสำหรับทั้งรายการ'
                    break;

                case 'discountOrderPercent':
                    placeholder = 'เปอร์เซ็นส่วนลดสำหรับทั้งรายการ'
                    break;
            }

            return placeholder;
        },
        displayDiscountUnit() {
            return Helper.displayPromotionAmountUnit(this.form.promotionType);
        },
        changeDeliveryRequireQty(value) {
            if (!value) {
                this.form.delivery.minQty = '';
            }
        },
        changeChargeEnable(value, property) {
            if (value) {
                this.form.installmentCharge[property].chargeType = 'percent';
            } else {
                this.form.installmentCharge[property].chargeType = '';
                this.form.installmentCharge[property].charge = '';
            }
        },
        changeInstallmentMaxPeriod(e) {
            this.generateInstallmentChargeList(e.target.value);
        },
        generateInstallmentChargeList(value) {
            this.installmentChargeList = [];

            if (value) {
                const list = [
                    { month: 3, property: 'month3' },
                    { month: 4, property: 'month4' },
                    { month: 6, property: 'month6' },
                    { month: 10, property: 'month10' }
                ];

                list.forEach((item) => {
                    if (item.month <= value) {
                        this.installmentChargeList.push(item);
                    }
                });
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/PromotionForm.scss';

.form {
    background: #f0f0f0;
    padding: 20px;
    @include borderRadius(8px);
}
</style>