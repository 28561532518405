import { dragscroll } from 'vue-dragscroll';

export default {
    directives: {
        dragscroll,
    },
    data: () => ({
        dragging: false,
        dragTimer: null,
    }),
    methods: {
        dragStart() {
            this.dragTimer = setTimeout(() => (this.dragging = true), 100);
        },
        dragEnd() {
            clearTimeout(this.dragTimer);
            setTimeout(() => (this.dragging = false));
        },
        dragClick(e) {
            if (this.dragging) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }
};